export const loadWebwhiz = () => {
  const script = document.createElement("script");
  script.setAttribute(
    "src",
    "https://widget.chat.groshi247.com/webwhiz-sdk.js"
  );
  script.setAttribute("id", "__webwhizSdk__");
  script.setAttribute("data-chatbot-id", "67ed0f353a559f7cdfcfbeef");

  document.body.appendChild(script);
};
